<template>
    <div>
        <el-dialog
                title="标引清单"
                :close-on-click-modal="false"
                v-dialogDrag
                width="80%"
                :visible.sync="visible" :before-close="close">
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-input size="small" maxlength="66" v-model="inputForm.name" placeholder="请输入文物名称" clearable></el-input>
                </el-col>
                <el-col :span="19">
                    <el-button type="primary" @click="search()" size="small" icon="el-icon-search">查询</el-button>
                </el-col>
            </el-row>
            <el-table
                    :data="dataList"
                    v-loading = "loading"
                    size="small"
                    height="calc(100% - 80px)"
                    class="table">
                <el-table-column
                        type="selection"
                        width="50">
                </el-table-column>
                <el-table-column show-overflow-tooltip
                        prop="exhibitionName"
                        label="展览名称">
                </el-table-column>
                <el-table-column
                        show-overflow-tooltip
                        prop="culturalName"
                        label="文物名称">
                </el-table-column>
                <el-table-column
                        prop="fileFormat"
                        label="附件类型">
                </el-table-column>
    <!--            <el-table-column-->
    <!--                    prop="pyear"-->
    <!--                    label="年度">-->
    <!--            </el-table-column>-->
                <el-table-column
                        show-overflow-tooltip
                        prop="archivalCode"
                        label="档号">
                </el-table-column>
    <!--            <el-table-column-->
    <!--                    show-overflow-tooltip-->
    <!--                    prop="fieldname1"-->
    <!--                    label="文件编号">-->
    <!--            </el-table-column>-->
                <el-table-column
                        show-overflow-tooltip
                        prop="writtenDate"
                        label="成文日期">
                </el-table-column>
                <el-table-column width="150"
                        fixed="right"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" plain @click="view(scope.row)">详情</el-button>
    <!--                    <el-button size="mini" type="success" plain @click="download(scope.row)">下载</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background=""
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <span slot="footer" class="dialog-footer">
              <el-button size="small" @click="close">关闭</el-button>
            </span>
        </el-dialog>
        <FileDetails ref="fileDetails"></FileDetails>
    </div>
</template>

<script>
    import FileDetails from '../../dataCollection/cultural/fileDetails' // 详情
    import ajax from 'axios';
    export default {
        components: {FileDetails},
        data() {
            return {
                dataList:[],
                visible: false,
                loading: false,
                inputForm: {
                    id: '',
                    name: '',
                },
                pageNo: 1,
                pageSize: 10,
                total: 0,

            }
        },
        methods: {
            search(){
                this.pageNo = 1
                this.init(this.inputForm.id);
            },
            init(id) {
                this.inputForm.id = id
                this.$axios(this.api.zlzs.queryAllFileListing, {
                    ...this.inputForm,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    console.log(data);
                    if(data.status && data){
                        let that = this;
                        this.dataList = data.data.records
                        this.dataList.forEach(function (v) {
                            v.url= that.$globalUrl()+v.url.substring(v.url.indexOf("\\"),v.url.length)
                        })
                        this.total = parseInt(data.data.total)
                        this.visible = true
                        this.loading = false
                    }
                })
            },
            close(){
                this.inputForm.name = ""
                this.visible = false
            },
            download(row){
                const a = document.createElement('a')
                //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                const url = row.url;  // 完整的url则直接使用
                // 这里是将url转成blob地址，
                fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                    a.href = URL.createObjectURL(blob)
                    console.log(a.href)
                    a.download = row.fileName // 下载文件的名字
                    // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
                    document.body.appendChild(a)
                    a.click()
                })
            },
            view(row){
                this.$refs.fileDetails.init("view", row, this.exhibitionId,"management")
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        }
    }
</script>
